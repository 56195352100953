import React from 'react';
import Link from 'next/link';
import Header from '../components/header.jsx';
import userPropType from '../utils/user-prop-type';

const IndexPage = ({ user }) => {
  if (user) {
    return (
      <>
        <Header back="" user={user} />
        <main role="main">
          <div>
            Hi, {user && user.name}!
          </div>
          <p>
            <Link href="/scan">
              <a href="/scan">Scan</a>
            </Link>
          </p>
        </main>
      </>
    );
  }

  return (
    <>
      <Header back="" />
      <main role="main">
        <div>Welcome to Authentise QR.</div>
        <p>
          <Link href="/log-in">
            <a href="/log-in">Log in to get started</a>
          </Link>
        </p>
      </main>
    </>
  );
};

IndexPage.propTypes = {
  user: userPropType.isRequired,
};

IndexPage.allowLoggedOut = true;

export default IndexPage;
